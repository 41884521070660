<template>
    <v-data-table
            :headers="headers"
            :items="users.data"
            :items-per-page="paginationParams.per_page"
            :page="paginationParams.page"
            :loading="loading"
            :server-items-length="users.total"
            sort-by="id"
            class="border"
            @update:page="changePage"
            @update:items-per-page="changeItemsPerPage"
            :footer-props="footerProps"
            @update:sort-by="sortBy"
            @update:sort-desc="sortDesc"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-dialog v-model="dialog" max-width="800px">
                    <user-form
                            v-if="dialogLoaded"
                            :itemId="editedItem"
                            v-on:saved="onSave"
                            v-on:updated="onUpdate"
                            v-on:close="close"
                    />
                </v-dialog>
            </v-toolbar>
            <v-container>
                <v-row>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.id"
                                :label="trans('fields.common.id')"
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.email"
                                :label="trans('fields.common.email')"
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-select
                                :items="options.plans"
                                :label="trans('fields.common.plan')"
                                :multiple="true"
                                v-model="filters.plan_id"
                        ></v-select>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-checkbox v-model="filters.notpayed" :value="1" :label="trans('pages.plan.unpayed')"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn
                            class="ma-2"
                            color="primary"
                            outlined
                            @click="applyFilters"
                    >{{ trans('buttons.common.apply') }}</v-btn>
                    <v-btn
                            class="ma-2"
                            color="orange"
                            outlined
                            @click="clearFilters"
                    >{{ trans('buttons.common.clear') }}</v-btn>
                </v-row>
            </v-container>
        </template>
        <template v-slot:no-data>
            <p>Не удалось загрузить данные</p>
            <v-btn color="primary" @click="initialize">Повторить</v-btn>
        </template>
        <template v-slot:loading>
            Идет загрузка
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>
        <template v-slot:item.plan_id="{ item }">
            <template v-if="item.plan_id">
                {{ item.plan.id }} :: {{ item.plan.label }}
            </template>
        </template>
        <template v-slot:item.plan_period="{ item }">
            <template v-if="item.plan_period && options.planPeriodsKeyed">
                {{ options.planPeriodsKeyed[item.plan_period] }}
            </template>
        </template>
        <template v-slot:item.user="{ item }">
            <user-link :user="item"></user-link>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <router-link :to="{name: 'admin.masters.users', params: {id: item.id}}"><v-icon small>mdi-eye</v-icon></router-link>
        </template>

    </v-data-table>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import UserLink from "@/components/admin/user/UserLink";
    import tableFilters from "@/mixins/tableFilters";

    export default {
        name: "MasterTable",
        mixins: [tableWithPagination, tableFilters],
        components: {
            UserLink,
            UserForm: () => import('@/components/admin/user/UserForm'),
        },
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                users: {},
                headers: [
                    { text: this.trans("fields.common.user"), value: "user", sortable: false },
                    { text: this.trans("fields.common.plan"), value: "plan_id", sortable: false },
                    { text: this.trans("fields.common.planPeriod"), value: "plan_period", sortable: false },
                    { text: this.trans("fields.common.created_at_m"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                filters: {
                    id: '',
                    email: '',
                    plan_id: '',
                    notpayed: null,
                },
                filtersDefault: {
                    id: '',
                    email: '',
                    plan_id: '',
                    notpayed: null,
                },
                url: this.route('admin.masters.index'),
            }
        },
        created() {
            this.editedItem = null;
            this.initialize();
            this.loadOptions({planPeriods: true, plans: true});
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            onUpdate(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                if (confirm("Are you sure you want to delete this item?")){
                    axios.post(this.route(this.deleteRoute, item.id)).then( () => {
                        this.initialize();
                    }).catch( (err) => {
                        console.log(err)
                    })
                }
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },
        },
        computed: {
            paginationItems: {
                get(){
                    return this.users;
                },
                set(v){
                    this.users = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
